import styled, { useTheme } from 'styled-components';
import { Button } from '@miri-unicorn/miricanvas-ds/v3';
import { Divider, Menu } from '@miri-unicorn/miricanvas-ds';
import { useTranslation } from '@configs/i18next';
import { ZendeskLinkType, getZendeskLink } from '@constants/zendesk_link';
import { CustomerGuideItem } from '@ui/common/help_center/HelpCenterPopup';
import { LaunchIconMenu } from '@ui/common/help_center/_components/LaunchIconMenu';
import { SubHeaderGuide } from '@ui/common/help_center/_components/SubHeaderGuide';

type MainGuideList = {
  onClose: () => void;
  allGuideList: CustomerGuideItem[];
  clickZendeskOpen: () => void;
  setChildDepthTitle: (title: string) => void;
  toChildGuideList: (childGuidList?: CustomerGuideItem[]) => void;
};
export const MainGuideList = ({
  onClose,
  allGuideList,
  clickZendeskOpen,
  setChildDepthTitle,
  toChildGuideList,
}: MainGuideList) => {
  const { translate } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Header>
        <Title>{translate('SHARED.HELP.TITLE')}</Title>
        <Button
          size={'S'}
          shape={'square'}
          color={'neutralLight'}
          onClick={onClose}
          variant={'ghost'}>
          <Button.Icon iconName={'close'} spinnerWhenLoading />
        </Button>
      </Header>
      <SubHeaderWrapper>
        <SubHeaderGuide />
      </SubHeaderWrapper>
      <Divider borderWidth={'thinest'} />
      <Content>
        {allGuideList.map((item, idx) => {
          if (item.link || item.showZendesk) {
            return (
              <LaunchIconMenu
                key={idx}
                menuName={translate(item.title)}
                onClick={
                  item.showZendesk
                    ? clickZendeskOpen
                    : () => window.open(getZendeskLink(item.link as ZendeskLinkType), '_blank')
                }
                selected={false}
              />
            );
          } else {
            return (
              <Menu
                key={idx}
                type={'menu'}
                size={'M'}
                selected={false}
                textOverflow={'newLine'}
                iconColor={theme.token.c_menu_color_contents_icon_leading}
                menuName={translate(item.title)}
                depthArrow={true}
                onClick={() => {
                  setChildDepthTitle(translate(item.title));
                  toChildGuideList(item.questions);
                }}
              />
            );
          }
        })}
      </Content>
    </>
  );
};

const Header = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 12px 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.token.s_color_border_default};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SubHeaderWrapper = styled.div`
  padding: 16px;
  width: 100%;
`;
const Title = styled.div`
  font: ${({ theme }) => theme.token.s_typography_title_S};
  color: ${({ theme }) => theme.token.s_color_contents_default};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  padding: 8px 16px 24px;
  width: inherit;
  height: calc(100% - 50px);
  overflow-y: auto;
`;
