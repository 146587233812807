import { MultiLangSeoMetaDataType } from '@configs/seo/_types/SeoMetaDataType';
import { MIRICANVAS_RESOURCE_DOMAIN } from '@constants/ResourceDomain';

const DEFAULT_KOREAN_OG_SITE_NAME = '미리캔버스';
const DEFAULT_GLOBAL_OG_SITE_NAME = 'MiriCanvas';
const DEFAULT_OG_IMAGE_WIDTH = '1200';
const DEFAULT_OG_IMAGE_HEIGHT = '675';
const DEFAULT_OG_TYPE = 'website';
const DEFAULT_RESTRICT_SCALE = false;

/**
 * 메인페이지 SEO 상수
 */
export const MAIN_PAGE_SEO = {
  ko: {
    title: '디자인 플랫폼 미리캔버스',
    desc: '디자인 작업이 필요한가요? 5만개 이상의 무료 템플릿으로 간편하고 편하게 원하는 디자인을 만들 수 있어요.',
    keywords: '무료 디자인 템플릿, 온라인 디자인 툴, 미리캔버스, Miricanvas, 저작권 무료',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_kr.png`,
    ogSiteName: `${DEFAULT_KOREAN_OG_SITE_NAME}`,
    ogDesc:
      '디자인 작업이 필요한가요? 5만개 이상의 무료 템플릿으로 간편하고 편하게 원하는 디자인을 만들 수 있어요.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  en: {
    title: 'MiriCanvas | Free design tool with unique templates',
    desc: 'Start talking visually. Make your message even more persuasive with MiriCanvas, within 3 minutes. Join now and enjoy all features for free.',
    keywords:
      'free design templates, unique design templates, free design tool, copyright free, MiriCanvas',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_en.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      'Start talking visually. Make your message even more persuasive with MiriCanvas, within 3 minutes. Join now and enjoy all features for free.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  ja: {
    title: '無料デザインプラットフォームMiriCanvas(ミリキャンバス)',
    desc: '素晴らしいデザインが必要ですか？MiriCanvas(ミリキャンバス)では、5万個以上のデザインテンプレートで、誰でも簡単で簡単に好きなデザインを作ることができます。',
    keywords:
      '無料デザインテンプレート, ユニークなデザイン テンプレート, 無料デザインツール, 著作権フリー, MiriCanvas, ミリキャンバス',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_jp.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      '素晴らしいデザインが必要ですか？MiriCanvas(ミリキャンバス)では、5万個以上のデザインテンプレートで、誰でも簡単で簡単に好きなデザインを作ることができます。',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  de: {
    title: 'MiriCanvas | Kostenloses Design-Tool mit einzigartigen Vorlagen',
    desc: 'Ein Bild sagt mehr als tausend Worte. Gestalte deine Botschaft noch überzeugender mit MiriCanvas, in nur 3 Minuten. Melde dich jetzt an und genieße alle Funktionen kostenlos.',
    keywords:
      'kostenlose Designvorlagen, einzigartige Designvorlagen, kostenloses Design-Tool, Copyright frei, MiriCanvas',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_en.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      'Ein Bild sagt mehr als tausend Worte. Gestalte deine Botschaft noch überzeugender mit MiriCanvas, in nur 3 Minuten. Melde dich jetzt an und genieße alle Funktionen kostenlos.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  es: {
    title: 'MiriCanvas | Herramienta de diseño gratis con plantillas únicas',
    desc: 'Empieza a hablar con visuales. Haz que tu mensaje sea aún más persuasivo en 3 minutos con MiriCanvas. Únete ahora y disfruta de todas las funciones gratis.',
    keywords:
      'plantillas de diseño gratis,plantillas de diseño únicas,herramienta de diseño gratis, sin derechos de autor,MiriCanvas',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_en.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      'Empieza a hablar con visuales. Haz que tu mensaje sea aún más persuasivo en 3 minutos con MiriCanvas. Únete ahora y disfruta de todas las funciones gratis.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  fr: {
    title: 'MiriCanvas | Outil de conception gratuit avec des modèles uniques',
    desc: 'Commencez à parler visuellement. En seulement 3 minutes, rendez votre message encore plus convaincant avec MiriCanvas. Rejoignez-nous dès maintenant et profitez de toutes les fonctionnalités gratuitement.',
    keywords:
      "modèles de conception gratuits, modèles de conception uniques, outil de conception gratuit, libre de droits d'auteur, MiriCanvas",
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_en.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      'Commencez à parler visuellement. En seulement 3 minutes, rendez votre message encore plus convaincant avec MiriCanvas. Rejoignez-nous dès maintenant et profitez de toutes les fonctionnalités gratuitement.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  it: {
    title: 'MiriCanvas | strumento di progettazione gratuito con modelli unici',
    desc: 'Inizia a parlare visivamente. Rendi il tuo messaggio ancora più persuasivo con MiriCanvas in 3 minuti. Iscriviti ora e goditi tutte le funzionalità gratuitamente.',
    keywords:
      'modelli di design unici e gratuiti, vari strumenti di design e copyright gratuiti. MiriCanvas',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_en.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      'Inizia a parlare visivamente. Rendi il tuo messaggio ancora più persuasivo con MiriCanvas in 3 minuti. Iscriviti ora e goditi tutte le funzionalità gratuitamente.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  nl: {
    title: 'MiriCanvas | Gratis ontwertool met unieke templates',
    desc: 'Begin visueel te spreken. Maak jouw boodschap nog overtuigender met MiriCanvas, binnen 3 minuten. Wordt nu lid and geniet gratis van alle functies.',
    keywords:
      'Gratis ontwerp templates, unieke ontwerp templates, gratis ontwerptool, auteursrecht vrij, MiriCanvas',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_en.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      'Begin visueel te spreken. Maak jouw boodschap nog overtuigender met MiriCanvas, binnen 3 minuten. Wordt nu lid and geniet gratis van alle functies.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
  pt: {
    title: 'MiriCanvas | Ferramenta de design gratuita com modelos únicos',
    desc: 'Comece a falar visualmente. Torne a sua mensagem ainda mais persuasiva com MiriCanvas, em 3 minutos. Registe-se agora e desfrute de todas as funcionalidades gratuitamente.',
    keywords:
      'modelos de design gratuitos,modelos de design únicos,ferramenta de design gratuita,sem direitos autorais,MiriCanvas',
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}2_0/image/og/OG_en.png`,
    ogSiteName: `${DEFAULT_GLOBAL_OG_SITE_NAME}`,
    ogDesc:
      'Comece a falar visualmente. Torne a sua mensagem ainda mais persuasiva com MiriCanvas, em 3 minutos. Registe-se agora e desfrute de todas as funcionalidades gratuitamente.',
    ogType: `${DEFAULT_OG_TYPE}`,
    ogImageWidth: `${DEFAULT_OG_IMAGE_WIDTH}`,
    ogImageHeight: `${DEFAULT_OG_IMAGE_HEIGHT}`,
    restrictScale: DEFAULT_RESTRICT_SCALE,
  },
} satisfies MultiLangSeoMetaDataType;
