import { LanguageType, LANGUAGE_TYPE_LIST } from '@constants/LanguageType';

const BASE_URL = 'https://www.miricanvas.com';
const TEMPLATE_BASE_PATH = 'templates';

/**
 * 템플릿 메인페이지 이후 path가 붙었을 때 append
 * @param templateMainPath 템플릿 메인페이지 주소
 * @param detailPath 추가로 붙일 path
 * @returns 템플릿 메인페이지 주소 + 추가로 붙일 path(optional)
 */
const appendPath = (templateMainPath: string, detailPath?: string): string => {
  if (detailPath) {
    return `${templateMainPath}/${detailPath}`;
  } else {
    return templateMainPath;
  }
};

/**
 * Alternate URL 생성 함수
 * LANGUAGE_TYPE_LIST에 있는 모든 언어에 대해 alternate URL 생성
 */
export const makeTemplateAlternateUrls = (detailPath?: string) => {
  return LANGUAGE_TYPE_LIST.map((lang: LanguageType) => {
    const templateMainPath = `${BASE_URL}/${lang}/${TEMPLATE_BASE_PATH}`;
    return {
      lang,
      href: appendPath(templateMainPath, detailPath),
    };
  });
};

export const makeMainpageAlternateUrls = () => {
  return LANGUAGE_TYPE_LIST.map((lang: LanguageType) => {
    const mainPagePath = `${BASE_URL}/${lang}`;
    return {
      lang,
      href: mainPagePath,
    };
  });
};
