import styled, { css } from 'styled-components';
import { Button } from '@miri-unicorn/miricanvas-ds/v3';

export const LaunchIconMenu = ({
  onClick,
  menuName,
  selected,
}: {
  onClick: () => void;
  menuName?: string;
  selected: boolean;
}) => {
  return (
    <MenuItemContainer onClick={onClick} selected={selected}>
      <TopContent>
        <LeftContentWrapper>{menuName}</LeftContentWrapper>
        <RightContentWrapper>
          <IconWrapper>
            <Button color={'neutralLight'} size={'2XS'} variant='ghost'>
              <Button.Icon iconName={'launch'} spinnerWhenLoading />
            </Button>
          </IconWrapper>
        </RightContentWrapper>
      </TopContent>
    </MenuItemContainer>
  );
};

const MenuItemContainer = styled.div<{
  selected?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 12px 10px 12px;
  font: 400 0.875rem/1.42 Pretendard Variable, Figtree, IBM Plex Sans JP;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${({ selected }) => selected && '#ffffff0d'};
  ${({ theme }) =>
    theme.mixin.hoverDesktopOnly(css`
      &:hover {
        background-color: #5a657617;
      }
    `)}
  &:active {
    background-color: #5a657726;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: end;
  gap: ${({ theme }) => theme.token['c_menu_spacing_itemGap_menu']};
`;

const IconWrapper = styled.span`
  display: flex;
`;
