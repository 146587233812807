import { FAVORITE_PAGE_SEO } from '@configs/seo/_constants/FavoritePageSeo';
import { MAIN_PAGE_SEO } from '@configs/seo/_constants/MainPageSeo';
import {
  BASE_TEMPLATE_SEO,
  TEMPLATE_DETAIL_SEO,
  TEMPLATE_SEARCH_SEO,
  TEMPLATE_SEARCH_TEMPLATE_TYPE_ID_SEO,
  TEMPLATE_TYPE_SEO,
} from '@configs/seo/_constants/TemplatePageSeo';
import { TemplateDetailSeoParams } from '@configs/seo/_types/TemplateDetailSeoParams';
import { TemplateSearchSeoParamsType } from '@configs/seo/_types/TemplateSearchSeoParamsType';
import { getEncodedKeyword } from '@configs/seo/utils/getEncodedKeyword';
import { getTemplateTypeTitleForSeo } from '@configs/seo/utils/getTemplateTypeTitleForSeo';
import {
  makeMainpageAlternateUrls,
  makeTemplateAlternateUrls,
} from '@configs/seo/utils/makeAlternateUrl';
import {
  makeMainpageCanonicalUrl,
  makeTemplateCanonicalUrl,
} from '@configs/seo/utils/makeCanonicalUrl';
import { replaceTemplateSeoString } from '@configs/seo/utils/replaceTemplateSeoString';
import { MIRICANVAS_RESOURCE_DOMAIN } from '@constants/ResourceDomain';
import { LanguageType } from '@miri-unicorn/miricanvas-editor/lib/types/miricanvas-shared-web/src/locale/LanguageType';
import { buildQueryString, isNonEmptyString } from '@miri-unicorn/miricanvas-utils/functions';

/** 유저의 언어 값이 undefined로 전달될 때를 대비한 기본 값 */
export const SEO_DEFAULT_LANGUAGE: LanguageType = 'ko';
/** 인덱싱 제외할 키워드 */
const EXCLUDE_KEYWORDS = ['망고보드'];

const isExcludeKeyword = (keyword: string = '') => {
  return EXCLUDE_KEYWORDS.includes(keyword);
};

/** 메인 페이지 SEO */
export const getMainSeo = (language: LanguageType = SEO_DEFAULT_LANGUAGE) => {
  return {
    ...MAIN_PAGE_SEO[language],
    canonical: makeMainpageCanonicalUrl(language),
    alternate: makeMainpageAlternateUrls(),
    language,
  };
};

/** 템플릿 메인 SEO */
export const getTemplateMainSeo = (language: LanguageType = SEO_DEFAULT_LANGUAGE) => {
  return {
    ...BASE_TEMPLATE_SEO[language],
    canonical: makeTemplateCanonicalUrl(language),
    alternate: makeTemplateAlternateUrls(),
    language,
  };
};

/**
 * 템플릿 타입 검색 SEO
 * ex) /templates/search?templateTypeId=presentation&purpose=ALL
 * ex) /templates/search?keyword=%ED%98%84%EC%B6%A9%EC%9D%BC&templateTypeId=detail_page&purpose=WEB
 */
export const getTemplateTypeSearchSeo = ({
  language = SEO_DEFAULT_LANGUAGE,
  templateTypeId,
  purpose,
  templateTypeTitle,
  keyword,
  hasTitleData,
}: TemplateSearchSeoParamsType) => {
  const searchKeywordQueryStr = buildQueryString({
    keyword,
    templateTypeId,
    purpose,
  });
  /**
   * TEMPLATE_SEARCH_TEMPLATE_TYPE_ID_SEO title, desc 부분의 "[검색어] [템플릿타입]"이 실제 값으로 대체 되도록 처리
   * ex) 무료 [검색어] [템플릿타입] 템플릿 - 미리캔버스
   * => 무료 비즈니스 카드뉴스 템플릿 - 미리캔버스
   * title 데이터가 안 들어올 경우 기본 title로 대체 (에러처리)
   */
  const baseTitle = TEMPLATE_SEARCH_TEMPLATE_TYPE_ID_SEO[language].title;
  const title = hasTitleData
    ? replaceTemplateSeoString(baseTitle, templateTypeTitle, keyword)
    : baseTitle;

  const baseDesc = TEMPLATE_SEARCH_TEMPLATE_TYPE_ID_SEO[language].desc;
  const desc = hasTitleData
    ? replaceTemplateSeoString(baseDesc, templateTypeTitle, keyword)
    : baseDesc;

  const noIndex = isExcludeKeyword(keyword);

  return {
    ...TEMPLATE_SEARCH_SEO[language],
    title,
    desc,
    ogDesc: desc,
    canonical: makeTemplateCanonicalUrl(language, `search?${searchKeywordQueryStr}`),
    alternate: makeTemplateAlternateUrls(`search?${searchKeywordQueryStr}`),
    language,
    noIndex,
  };
};

/**
 * 템플릿 검색 SEO
 * ex) /templates/search?keyword=''
 */
export const getTemplateSearchSeo = (
  language: LanguageType = SEO_DEFAULT_LANGUAGE,
  keyword: string
) => {
  /**
   * TEMPLATE_SEARCH_TEMPLATE_TYPE_ID_SEO title, desc 부분의 "[검색어]"가 실제 값으로 대체 되도록 처리
   * ex) 무료 [검색어] 템플릿 - 미리캔버스
   * => 무료 비즈니스 템플릿 - 미리캔버스
   */
  const title = replaceTemplateSeoString(TEMPLATE_SEARCH_SEO[language].title, '', keyword);
  const desc = replaceTemplateSeoString(TEMPLATE_SEARCH_SEO[language].desc, '', keyword);

  const searchQueryStr = buildQueryString({
    keyword,
  });
  const canonicalUrl = searchQueryStr
    ? makeTemplateCanonicalUrl(language, `search?${searchQueryStr}`)
    : makeTemplateCanonicalUrl(language, 'search');
  const alternate = searchQueryStr
    ? makeTemplateAlternateUrls(`search?${searchQueryStr}`)
    : makeTemplateAlternateUrls('search');
  const noIndex = isExcludeKeyword(keyword);
  return {
    ...TEMPLATE_SEARCH_SEO[language],
    title,
    desc,
    ogDesc: desc,
    canonical: canonicalUrl,
    alternate,
    language,
    noIndex,
  };
};

/**
 * 템플릿 정적 페이지 검색 SEO
 * ex) /templates/search/[keyword]
 */
export const getTemplateStatcSearchSeo = (
  language: LanguageType = SEO_DEFAULT_LANGUAGE,
  keyword: string
) => {
  const title = replaceTemplateSeoString(TEMPLATE_SEARCH_SEO[language].title, '', keyword);
  const desc = replaceTemplateSeoString(TEMPLATE_SEARCH_SEO[language].desc, '', keyword);
  const encodedKeyword = encodeURIComponent(keyword);
  const canonicalKeyword = language === 'en' ? keyword : encodedKeyword;
  return {
    ...TEMPLATE_SEARCH_SEO[language],
    title,
    desc,
    ogDesc: desc,
    canonical: makeTemplateCanonicalUrl(language, `search/${canonicalKeyword}`),
    alternate: makeTemplateAlternateUrls(`search/${encodedKeyword}`),
    language,
  };
};

/** 템플릿 상세 SEO */
export const getTemplateDetailSeo = ({
  language = SEO_DEFAULT_LANGUAGE,
  title,
  imgUrl,
  templateTypeTitle,
  page,
  seoTitle,
  seoKeywords,
  templateIdx,
  templateTypeId,
}: TemplateDetailSeoParams) => {
  return {
    ...TEMPLATE_DETAIL_SEO[language],
    title: `${seoTitle}${TEMPLATE_DETAIL_SEO[language].title}`,
    ogImageUrl: imgUrl,
    keywords: isNonEmptyString(seoKeywords) ? seoKeywords : `${templateTypeTitle}, ${title}`,
    ogImageHeight: `${(page.height * 400) / page.width}`,
    /**
     * 템플릿 상세 페이지 no-index 처리
     * */
    noIndex: true,
    canonical: makeTemplateCanonicalUrl(
      language,
      `${templateTypeId}/` + getEncodedKeyword(templateIdx as string)
    ),
    alternate: makeTemplateAlternateUrls(
      `${templateTypeId}/` + getEncodedKeyword(templateIdx as string)
    ),
    language,
  };
};

/**
 * TemplateTypeId seo 데이터
 * ex) https://www.miricanvas.com/templates/presentation
 * */
export const getTemplateTypeIdSeoConfig = (
  language: LanguageType = SEO_DEFAULT_LANGUAGE,
  templateTypeId: string,
  templateTypeTitle: string
) => {
  const templateTypeTitleForSeo = getTemplateTypeTitleForSeo(templateTypeTitle);
  const title = replaceTemplateSeoString(
    TEMPLATE_SEARCH_SEO[language].title,
    templateTypeTitleForSeo,
    ''
  );
  const desc = replaceTemplateSeoString(
    TEMPLATE_SEARCH_SEO[language].desc,
    templateTypeTitleForSeo,
    ''
  );
  return {
    ...getTemplateMainSeo(language),
    title,
    desc,
    ogDesc: desc,
    ogImageUrl: `${MIRICANVAS_RESOURCE_DOMAIN}image/web/templates/main/type_thumbnail/${templateTypeId}.png`,
    keywords: `무료 ${templateTypeTitleForSeo}, ${templateTypeTitleForSeo} 디자인, ${templateTypeTitleForSeo} 템플릿, 미리캔버스, miricanvas`,
    canonical: makeTemplateCanonicalUrl(language, `${templateTypeId}`),
    alternate: makeTemplateAlternateUrls(`${templateTypeId}`),
    language,
  };
};
/**
 * 타입 별로 보기
 * https://www.miricanvas.com/templates/type
 */
export const getTemplateTypeSeoConfig = (language: LanguageType = SEO_DEFAULT_LANGUAGE) => {
  return {
    ...TEMPLATE_TYPE_SEO[language],
    canonical: makeTemplateCanonicalUrl(language, 'type'),
    alternate: makeTemplateAlternateUrls('type'),
    language,
  };
};
/**
 * 찜 페이지의 SEO 설정
 * 정해진 내용이 없기 때문에 기본 SEO 설정만 적용
 * TODO: 프프와 논의 후 SEO 변경, 타이틀 국제화 작업
 */
export const getFavoritePageSeoConfig = (language: LanguageType = SEO_DEFAULT_LANGUAGE) => {
  return {
    ...FAVORITE_PAGE_SEO[language],
    language,
  };
};
