import React from 'react';
import { Typography, Button } from '@miri-unicorn/miricanvas-ds/v3';
import styled from 'styled-components';
import { useTranslation } from '@configs/i18next';
import { getZendeskLink } from '@constants/zendesk_link';

export const Footer = () => {
  const { translate } = useTranslation();
  return (
    <FooterContainer>
      <Button
        size={'M'}
        shape={'square'}
        onClick={() => window.open(getZendeskLink('HELP_CENTER_MAIN'), '_blank')}
        color={'neutral'}
        width={'100%'}
        variant={'filled'}>
        <Typography ellipsis>{translate('SHARED.SHARED_337')}</Typography>
      </Button>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.token.s_color_border_default};
`;
